import './index.styl'
import { Fragment, MouseEventHandler, ReactElement } from 'react'
import { Button } from '../Button'
import classNames, { Argument } from 'classnames'
import { Price } from '../Price'
import { Title } from '../Title'
import { Logo, LogoProps } from '../Logo'
import { Loader } from '../Loader'
import { Translate } from '../../translations/CebiaTranslator'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'
import { AtrOverviewSale } from '../AtrOverViewSale'
import { LanguageType } from '../../enums/LanguageType'

type Props = DefaultProps | FixedProps

type DefaultProps = {
    variant: 'default' | 'mobile'
    title: string
    vin: string
    mobileSnap?: boolean
    footerTitle: string
    priceBefore: string
    priceAfter: string
    showDiscount?: boolean
    button: string
    className?: Argument
    logo: LogoProps
    isLoading: boolean | undefined
    priceInfo: DashBoardJsonResult
    disabled: boolean
    onclick?: MouseEventHandler<HTMLElement> | undefined
}
type FixedProps = {
    variant: 'fixed'
    title: string
    // vin: string
    // footerTitle: string
    priceBefore: string
    priceAfter: string
    showDiscount?: boolean
    button: string
    className?: Argument
    isLoading: boolean | undefined
    priceInfo: DashBoardJsonResult
    disabled: boolean
    onclick?: MouseEventHandler<HTMLElement> | undefined
    // logo: LogoProps
}

export const AtrOverview = (props: Props): ReactElement => {
    const tran = new Translate()
    const nextSale = 50

    const className = classNames(
        'AtrOverview',
        {
            [`AtrOverview--${props.variant}`]: props.variant,
        },
        props.className,
    )
    if (props.variant === 'fixed') {
        const { title, priceBefore, priceAfter, button, showDiscount } = props
        return (
            <article className={className}>
                <div className="AtrOverview-inner">
                    <span className="AtrOverview-title smMax:hidden">{title}</span>
                    {props.isLoading ? (
                        <Loader variant="bars" />
                    ) : (
                        <>
                            <Price
                                variant="small"
                                priceBefore={priceBefore}
                                priceAfter={priceAfter}
                                showDiscount={showDiscount}
                            />
                            <Button
                                disabled={props.disabled}
                                as="button"
                                className="AtrOverview-button"
                                onClick={props.onclick}
                            >
                                {button}
                            </Button>
                        </>
                    )}
                </div>
            </article>
        )
    } else {
        const {
            title,
            vin,
            mobileSnap = false,
            footerTitle,
            priceBefore,
            priceAfter,
            button,
            logo,
            priceInfo,
            disabled,
            onclick,
        } = props

        return (
            <article
                className={classNames(className, {
                    [`AtrOverview--mobileSnap`]: mobileSnap,
                })}
            >
                {props.variant == 'default' && (
                    <>
                        <div className="AtrOverview-banner">
                            <div className="AtrOverview-logoWrap">
                                <Logo className="AtrOverview-logo" {...logo} />
                            </div>
                        </div>

                        <strong className="AtrOverview-title mb-10 fw400">
                            {title.split('|').map((item, index) => (
                                <Fragment key={index}>
                                    {item && item.length > 0 ? (
                                        <>
                                            {item} <br></br>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Fragment>
                            ))}
                        </strong>
                        <span className="AtrOverview-vin">
                            {' '}
                            {tran.GetText('VIN:')} {vin}
                        </span>
                    </>
                )}
                {/* {priceInfo &&
                    priceInfo.sale &&
                    (priceInfo.sale.nl_sleva_proc > 0 || priceInfo.sale.nd_sleva_pevna > 0) && (
                        <>
                            <AtrOverviewSale priceInfo={priceInfo}></AtrOverviewSale>
                        </>
                    )} */}
                {((priceInfo &&
                    priceInfo.countryId == LanguageType.CZ &&
                    [4, 14].includes(priceInfo?.productId ?? 0)) ||
                    priceInfo.countryId == LanguageType.RO ||
                    priceInfo.countryId == LanguageType.DE ||
                    priceInfo.countryId == LanguageType.UK ||
                    priceInfo.countryId == LanguageType.US ||
                    priceInfo.countryId == LanguageType.SK ||
                    priceInfo.countryId == LanguageType.IT ||
                    priceInfo.countryId == LanguageType.FR ||
                    priceInfo.countryId == LanguageType.NL ||
                    ([4, 14].includes(priceInfo?.productId ?? 0) &&
                        priceInfo.countryId != LanguageType.CZ &&
                        priceInfo.countryId != LanguageType.AL)) && (
                    <>
                        {props.isLoading ? (
                            <></>
                        ) : (
                            <AtrOverviewSale
                                priceInfo={priceInfo}
                                nextSale={(() => {
                                    switch (priceInfo.countryId) {
                                        case LanguageType.CZ:
                                        case LanguageType.US:
                                        case LanguageType.DE:
                                        case LanguageType.RO:
                                        case LanguageType.IT:
                                        case LanguageType.FR:
                                        case LanguageType.NL:
                                        case LanguageType.SK:
                                            return 50
                                        case LanguageType.UK:
                                            return 35
                                        default:
                                            return 20
                                    }
                                })()}
                            ></AtrOverviewSale>
                        )}
                    </>
                )}

                <div
                    className={`AtrOverview-footer ${
                        props.variant == 'default' ? 'mdMax:hidden' : ''
                    } `}
                >
                    <Title as="span" variant="withDash" className="font-xl mb-10">
                        {footerTitle}
                    </Title>
                    {props.isLoading ? (
                        <Loader variant="bars" />
                    ) : (
                        <>
                            {((priceInfo &&
                                priceInfo.countryId == LanguageType.CZ &&
                                [3].includes(priceInfo?.productId ?? 0)) ||
                                (priceInfo && priceInfo.countryId === LanguageType.AL) || // pro AL vždy
                                ([3].includes(priceInfo?.productId ?? 0) &&
                                    priceInfo.countryId != LanguageType.CZ &&
                                    priceInfo.countryId != LanguageType.AL)) && (
                                <ul className="pb-30">
                                    <li>
                                        <span className="Icon Icon--check mr-10 color-green"></span>
                                        {tran.GetText('Prověření historie vozidla')}
                                    </li>
                                    <li>
                                        <span className="Icon Icon--check mr-10 color-green"></span>
                                        {tran.GetText('Kontrola závazků vozidla')}
                                    </li>
                                </ul>
                            )}

                            <Price
                                className=" mb-20"
                                variant="small"
                                priceBefore={priceBefore}
                                priceAfter={priceAfter}
                            />

                            <Button as="button" disabled={disabled} onClick={onclick}>
                                {button}
                            </Button>
                        </>
                    )}
                </div>
            </article>
        )
    }
}
